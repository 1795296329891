import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3fb498',
      light: '#3fb498',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#6b3eff',
      light: '#6b3eff',
    },
    background: {
      default: '#f6f6f6',
      paper: '#ffffff',
    },
    text: {
      primary: '#3e505b',
      secondary: '#778192',
      disabled: '#b9c1ce',
      hint: '#b9c1ce',
    },
    error: {
      main: '#eb5757',
      light: '#eb5757',
    },
    warning: {
      main: '#eba757',
      light: '#eba757',
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#3e505b',
        color: '#fff',
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontWeight: 700,
      fontSize: 26,
    },
    h2: {
      fontSize: 21,
    },
    h3: {
      fontSize: 18,
    },
    h4: {
      fontSize: 15,
      fontWeight: 300,
    },
    body1: {
      fontFamily: 'Inter',
      fontSize: 18,
    },
    button: {
      fontFamily: 'Inter',
      textTransform: 'none'
    },
  },
});

export default theme;
