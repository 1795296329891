export const size = {
  minScreen: "550px",
  mobile: "768px",
  tablet: "1024px",
  laptop: "1350px",
  book: "1400px",
  minHeight: "1024px",
};

export const device = {
  minHeight: `(min-height: ${size.minHeight})`,
  minScreen: `(max-width: ${size.minScreen})`,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  book: `(max-width: ${size.book})`,
};
