import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

/* const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => { */
const ProtectedRoute = ({ redirectPath = "/", children }) => {
  let isAllowed =
    Cookies.get("isRegistered") === "true"
      ? Cookies.get("isAuthenticated") === "true"
      : Cookies.get("isAnonymous") === "true";

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
