import React from "react";

export default function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(() => {
    const persistedState = localStorage.getItem(key);
    if (persistedState) {
      let parsedValue = JSON.parse(persistedState);
      if (parsedValue && (key === "paymentDate" || key === "issueDate")) {
        return new Date(JSON.parse(persistedState));
      }
      return parsedValue;
    }
    return defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);
  return [state, setState];
}
