import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { device } from "../../layouts/device-size";

export const Flex = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  max-width: ${(props) => props.maxWidth || "unset"};
  min-width: ${(props) => props.minWidth || "unset"};
  max-height: ${(props) => props.maxHeight || "unset"};
  min-height: ${(props) => props.minHeight || "unset"};
  flex-direction: ${(props) => props.direction || "unset"};
  align-items: ${(props) => props.align || "center"};
  justify-content: ${(props) => props.justify || "center"};
  flex-wrap: ${(props) => props.wrap || "unset"};
  text-align: ${(props) => props.textAlign || "unset"};
  padding: ${(props) => props.padding || "unset"};
  margin: ${(props) => props.margin || "unset"};
  gap: ${(props) => props.gap || "unset"};
  border-radius: ${(props) => props.borderRadius || "0"};
  background: ${(props) => props.background || "transparent"};
`;

export const ResponsiveFlex = styled(Flex)`
  max-width: 85%;
  @media ${device.mobile} {
    max-width: 95%;
  }
`;

Flex.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  direction: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  wrap: PropTypes.string,
  textAlign: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  gap: PropTypes.string,
  borderRadius: PropTypes.string,
  background: PropTypes.string,
};
