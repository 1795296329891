import React from "react";
import Box from "@mui/material/Box";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CheckTextbox = ({ text }) => {
  return (
    <Box display="flex" alignItems="center">
      <CheckCircleIcon color="primary" sx={{ zIndex: "modal" }} />
      <Box backgroundColor="#FFFFFF" borderRadius={7} height="5.5rem" display="flex" alignItems="center" marginLeft="-10px" minWidth="12rem" justifyContent="center" paddingX="1rem" marginY="1rem">
        {text}
      </Box>
    </Box>
  );
};

export default CheckTextbox;
