import React from "react";
import usePersistedState from "./usePersistedState";

export const Context = React.createContext();

export const StateProvider = ({ children }) => {
  const [loginToken, setToken] = usePersistedState("loginToken", "");
  const [sidebarOption, setSidebarOption] = usePersistedState(
    "sidebarOption",
    "nuevo"
  );
  const [issueTab, setIssueTab] = usePersistedState("issueTab", "fisico");
  const [fceChecked, setFceChecked] = usePersistedState("fceChecked", false);
  const [processingError, setError] = usePersistedState(
    "processingError",
    false
  );
  const [tableFilter, setTableFilter] = usePersistedState(
    "tableFilter",
    "Ver todas las ofertas"
  );
  const [userRegistered, setUserRegistered] = usePersistedState(
    "userRegistered",
    true
  );
  const [termsChecked, setTermsChecked] = usePersistedState(
    "termsChecked",
    false
  );
  const [producerReferral, setProducerReferral] = usePersistedState(
    "producerReferral",
    null
  );

  return (
    <Context.Provider
      value={{
        loginToken,
        setToken,
        sidebarOption,
        setSidebarOption,
        issueTab,
        setIssueTab,
        processingError,
        setError,
        tableFilter,
        setTableFilter,
        userRegistered,
        setUserRegistered,
        fceChecked,
        setFceChecked,
        termsChecked,
        setTermsChecked,
        producerReferral,
        setProducerReferral,
      }}
    >
      {children}
    </Context.Provider>
  );
};
