import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import { ChildrenDiv, Wrapper } from "./layout-styles";
import EcoChatButton from "../common/buttons/ecochat-button";
import { Context } from "../../context/global-context-provider";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MuiListItem from "@mui/material/ListItem";
import MuiListItemButton from "@mui/material/ListItemButton";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SidebarLogo from "../../assets/logos/eco-pymes-sidebar-logo.svg";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DocIcon from "@mui/icons-material/ArticleOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import styled from "@emotion/styled";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { requestHandler } from "../../utils/request-handler";
import { device } from "./device-size";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Layout = () => {
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const context = React.useContext(Context);
  const navigate = useNavigate();
  const [, startTransition] = React.useTransition();
  const drawerWidth = 165;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    context.setIssueTab("Cheque físico");
    Cookies.set("issuer", "");
    if (Cookies.get("isAnonymous") === "true") {
      let data = "";
      requestHandler("/flush-session/", data, "post")
        .then((response) => {
          if (response.status === 204) {
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");
            Cookies.remove("isAnonymous");
            Cookies.remove("isRegistered");
            navigate("/");
          }
        })
        .catch((error) => { });
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("isAnonymous");
      Cookies.remove("isRegistered");
      Cookies.remove("captchaVerified");
      navigate("/");
    } else {
      let data = JSON.stringify({
        refresh: `${Cookies.get("refreshToken")}`,
      });
      requestHandler("/auth/token/blacklist/", data, "post")
        .then((response) => {
          if (response.status === 200) {
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");
            Cookies.remove("isAnonymous");
            Cookies.remove("isRegistered");
            Cookies.remove("isAuthenticated");
            navigate("/");
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  };

  const topButtons = [
    {
      label: "Nuevo",
      icon: <AddIcon fontSize="small" sx={{ color: "white" }} />,
      activePathNameRegex:
        /\/issue-selector\/|\/cheque-upload\/|\/cheque-preview\//,
      link: "/issue-selector/echeq",
    },
  ];
  if (Cookies.get("isRegistered") === "true") {
    topButtons.push({
      label: "Documentos",
      icon: <DocIcon fontSize="small" sx={{ color: "white" }} />,
      activePathNameRegex: /\/offer-table\//,
      link: "/offer-table",
    });
  }

  const bottomButtons = [
    {
      label: "Cerrar sesión",
      icon: <LogoutIcon fontSize="small" sx={{ color: "white" }} />,
      onClick: handleLogout,
    },
  ];

  const ListItemButton = styled(MuiListItemButton)`
    & .MuiTypography-root {
      font-size: 0.95rem;
    }

    &.Mui-selected {
      background-color: #313c43;
    }

    &.Mui-selected:hover {
      background-color: #313c43;
    }

    color: white;
  `;

  const ListItem = styled(MuiListItem)`
    &:hover {
      background-color: #313c43;
    }
  `;

  const FooterListItem = styled(ListItem)`
    background-color: #566670;
  `;

  const ListItemIcon = styled(MuiListItemIcon)`
    min-width: 2.26rem;
  `;
  const drawer = (
    <div>
      <Divider />
      <List disablePadding>
        {topButtons.map((topButton) => (
          <ListItem key={topButton.label} disablePadding>
            <ListItemButton
              selected={
                location.pathname.match(topButton.activePathNameRegex) !== null
              }
              onClick={() => startTransition(() => navigate(topButton.link))}
            >
              <ListItemIcon>{topButton.icon}</ListItemIcon>
              <ListItemText primary={topButton.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          textAlign: "center",
        }}
      >
        <Divider />
        {bottomButtons.map((bottomButton) => (
          <FooterListItem key={bottomButton.label} disablePadding>
            <ListItemButton onClick={bottomButton.onClick}>
              <ListItemIcon>{bottomButton.icon}</ListItemIcon>
              <ListItemText primary={bottomButton.label} />
            </ListItemButton>
          </FooterListItem>
        ))}
      </div>
    </div>
  );

  const hideChat =
    (location.pathname.match(/\/offer-data\//) ||
      location.pathname === "/offer-table") &&
    !context.processingError;

  return (
    <Wrapper>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: { xs: "block", sm: "none" },
          bgcolor: "#3E505B",
          zIndex: 1201,
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            {mobileOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <img
            src={SidebarLogo}
            alt="logo"
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ display: { xs: "block", sm: "none" } }} />
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#3E505B",
          },
        }}
      >
        <Toolbar disableGutters></Toolbar>
        {drawer}
      </Drawer>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#3E505B",
          },
          display: { xs: "none", sm: "block" },
        }}
        variant="permanent"
        anchor="left"
        open
      >
        <Toolbar disableGutters>
          <Logo src={SidebarLogo} alt="eco-pymes-logo" />
        </Toolbar>

        {drawer}
      </Drawer>
      <ChildrenDiv>
        <Outlet />
        {hideChat ? null : (
          <EcoChatButton
            large
            position="absolute"
            bottom="105px"
            right="80px"
          />
        )}
      </ChildrenDiv>
    </Wrapper>
  );
};
const Logo = styled.img`
  padding: 20px 24px;
  @media ${device.tablet} {
    display: none;
  }
`;

export default Layout;
