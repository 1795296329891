import axios from "axios";
import Cookies from "js-cookie";

axios.defaults.withCredentials = true;

export const requestHandler = async (path, data, type) => {
  const anonymous = Cookies.get("isAnonymous");
  const url = process.env.REACT_APP_API_URL;
  let config;
  if (
    path !== "/validate-recaptcha/" &&
    path !== "/auth/token/refresh/" &&
    anonymous === "true" &&
    type === "post"
  ) {
    await axios
      .get(`${url}/generate-csrf-token/`)
      .then((response) => {
        if (response && (response.status === 204 || response.status === 200)) {
          config = {
            method: type,
            url: `${url}${path}`,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": `${response.data["csrf-token"]}`,
            },
            data: data ? data : undefined,
          };
        } else {
        }
      })
      .catch((error) => {
        console.warn(error);
        window.location = "/";
      });
  } else {
    config = {
      method: type,
      url: `${url}${path}`,
      headers:
        path === "/validate-recaptcha/" ||
        path === "/auth/token/refresh/" ||
        path === "/generate-csrf-token/" ||
        path === "/rates-graph-data/" ||
        anonymous === "true"
          ? {
              "Content-Type": "application/json",
            }
          : {
              Authorization:
                path === "/auth/token/"
                  ? ""
                  : `Bearer ${Cookies.get("accessToken")}`,
              "Content-Type":
                path === "/recognize-routing-code/"
                  ? "multipart/form-data; boundary=X-INSOMNIA-BOUNDARY"
                  : "application/json",
            },
      data: data ? data : undefined,
    };
  }
  return axios(config);
};
