import styled from "@emotion/styled";
import { device } from "../../layouts/device-size";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const EcoChatButton = ({ large, position, top, right, bottom, left }) => (
  <StyledDiv
    position={position}
    top={top}
    right={right}
    bottom={bottom}
    left={left}
  >
    <Label large>¿Necesitás asistencia?</Label>
    <a
      href="https://wa.me/5491125017985?text=hola"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <Button large>
        <WhatsAppIcon sx={{ fontSize: 21 }} />
        Eco chat
      </Button>
    </a>
  </StyledDiv>
);

export default EcoChatButton;

const StyledDiv = styled.div`
  position: ${(props) => props.position || "absolute"};
  top: ${(props) => props.top || "unset"};
  right: ${(props) => props.right || "unset"};
  bottom: ${(props) => props.bottom || "unset"};
  left: ${(props) => props.left || "unset"};
  z-index: 1200;

  @media ${device.tablet} {
    bottom: 30px;
    right: 20px;
    position: fixed;
  }
  @media ${device.mobile} {
    position: relative;
    text-align: center;
    margin-top: 10px;
    right: 0;
    bottom: 0;
  }
`;

const Label = styled.label`
  font-family: "inter";
  font-weight: ${(props) => (props.large ? 500 : 400)};
  font-size: ${(props) => (props.large ? "17px" : " 14px")};
  color: #3fb498;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: 0.3s;
  background: #6b3eff;
  padding: 0px 20px;
  border: 0;
  outline: 0;
  color: #ffffff;
  cursor: pointer;
  font-family: "inter";
  font-weight: ${(props) => (props.large ? 700 : 600)};
  font-size: ${(props) => (props.large ? "18px" : " 15px")};
  height: ${(props) => (props.large ? "46px" : "36px")};
  border-radius: ${(props) => (props.large ? "10px" : "7px")};
  box-shadow: ${(props) =>
    props.large ? "0px 0px 10px rgba(135, 155, 179, 0.75)" : "0"};
  margin: 10px 0px 0px 20px;

  &:focus {
    border: 0;
    outline: 0;
  }

  &:hover {
    background: #502dc4;
  }
`;
