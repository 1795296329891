import React, { Suspense, lazy } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Radio from "@mui/material/Radio";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Cookies from "js-cookie";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EcoLogo from "../../assets/logos/logo_ecopymes.webp";
import EcoLogo2 from "../../assets/logos/logo2.webp";
import { requestHandler } from "../../utils/request-handler";
import Button from '@mui/material/Button';
import { Context } from "../../context/global-context-provider";
import smartphoneillustration from "../../assets/images/mob.webp";
import matbalogo from "../../assets/partners/matba.webp";
import argentinaclearinglogo from "../../assets/partners/argentinaclearing.webp";
import bymalogo from "../../assets/partners/byma.webp";
import mavlogo from "../../assets/partners/mav.webp";
import cnvlogo from "../../assets/partners/cnv.webp";
import cajavaloreslogo from "../../assets/partners/cajadevalores.webp";
import RadioGroup from "@mui/material/RadioGroup";
import ListItem from '@mui/material/ListItem';
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Spinner from "../../components/common/spinner/spinner";
import CheckTextbox from "../../components/common/check-textbox/check-textbox";
import Grid from '@mui/material/Unstable_Grid2';
import Box from "@mui/material/Box";
import GppGoodIcon from '@mui/icons-material/GppGood';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextField from '@mui/material/TextField';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
const ReCAPTCHA = lazy(() => import("react-google-recaptcha"));
const RateChart = lazy(() => import("../../components/charts/rate-chart"));

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const context = React.useContext(Context);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [, startTransition] = React.useTransition();
  const { producerReferral = null } = useParams();
  const [searchParams,] = useSearchParams();
  const emailRegex =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const onlyNumbersRegex = /^[0-9]+$/;

  const handleLogin = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get('email')
    let areaCode = data.get('phone-area-code')
    let phoneNumber = data.get('phone-number')
    let captchaResponse = data.get('g-recaptcha-response')
    let hasMipymeCertificate = data.get('radio-buttons-group')
    if (window.location.hostname !== "localhost") {
      window.dataLayer.push({
        enhanced_conversion_data: {
          email: email,
        },
      });
      window.dataLayer.push({
        event: "ingresarbutton",
      });
    }
    let has_error = false

    if (!email) {
      setSnackbarMessage("Por favor ingrese un email");
      has_error = true
    } else if (!emailRegex.test(email)) {
      setSnackbarMessage("Por favor ingrese un email válido");
      has_error = true
    } else if (!areaCode) {
      setSnackbarMessage("Por favor ingrese código de área");
      has_error = true
    } else if (areaCode.length < 2 || !onlyNumbersRegex.test(areaCode)) {
      setSnackbarMessage("Por favor ingrese un código de área válido");
      has_error = true
    } else if (!phoneNumber) {
      setSnackbarMessage("Por favor ingrese un número de teléfono");
      has_error = true
    } else if (phoneNumber.length < 6 || !onlyNumbersRegex.test(phoneNumber)) {
      setSnackbarMessage("Por favor ingrese un número de teléfono válido");
      has_error = true
    } else if (!captchaResponse) {
      setSnackbarMessage("Por favor verifique el reCAPTCHA");
      has_error = true
    }
    if (has_error) {
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    let config = {
      "g-recaptcha-response": captchaResponse,
      email: email,
      phone_number: areaCode.concat(phoneNumber),
      has_mipyme_certificate: hasMipymeCertificate == "yes" ? true : false,
      gclid: searchParams.get("gclid"),
      utm_medium: searchParams.get("utm_medium"),
      utm_source: searchParams.get("utm_source"),
      utm_campaign: searchParams.get("utm_campaign"),
    };

    requestHandler("/validate-recaptcha/", "get")
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          requestHandler("/validate-recaptcha/", config, "post")
            .then((response) => {
              if (response.status === 204 || response.status === 200) {
                Cookies.set("captcha", captchaResponse);
                Cookies.set("captchaVerified", "true");
                Cookies.set("isRegistered", "false");
                Cookies.set("isAnonymous", "true");
                context.setProducerReferral(producerReferral);
                setTimeout(() => {
                  Cookies.remove("isAnonymous");
                  Cookies.remove("isAuthenticated");
                  navigate("/");
                }, 3 * 60 * 60 * 1000);
                startTransition(() => navigate("/issue-selector/echeq"));
                /* navigate("/issue-selector"); */
              } else {
                setSnackbarMessage("Captcha not verified by server");
                setSnackbarOpen(true);
              }
            })
            .catch((error) => {
              setSnackbarMessage(
                "Por favor habilite las cookies de terceros y actualice la página"
              );
              setSnackbarOpen(true);
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      });
  };

  React.useEffect(() => {
    // Esto es para salvar el caso donde el usuario vió el instructivo de FCE, pero no hizo click en "continuar" y cerró la página
    // (sin esto, entraría en la tab de echeq mostrando el instructivo de fce)
    context.setIssueTab("echeq");
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert onClose={() => setSnackbarOpen(false)} severity="error" variant="filled">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        height={{ xs: "10.725vh", sm: "10.332vh" }}
      >
        <Grid xs={4} sm={1} display="flex" justifyContent="center">
          <img src={matbalogo} alt="matba logo" width="80%" />
        </Grid>
        <Grid xs={4} sm={1} display="flex" justifyContent="center">
          <img src={argentinaclearinglogo} alt="argentina clearing logo" width="80%" />
        </Grid>
        <Grid xs={4} sm={1} display="flex" justifyContent="center">
          <img src={bymalogo} alt="byma logo" width="80%" />
        </Grid>
        <Grid xs={4} sm={1} display="flex" justifyContent="center">
          <img src={mavlogo} alt="mav logo" width="80%" />
        </Grid>
        <Grid xs={4} sm={1} display="flex" justifyContent="center">
          <img src={cnvlogo} alt="cnv logo" width="80%" />
        </Grid>
        <Grid xs={4} sm={1} display="flex" justifyContent="center">
          <img src={cajavaloreslogo} alt="caja de valores logo" width="80%" />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
      >
        <Grid
          container
          xs={11}
          sm={9}
        >
          <Grid
            container
            xs={12}
            sm={7}
            direction="column"
            justifyContent="center"
            order={{ xs: 2, sm: 1 }}
          >
            <Grid container>
              <Grid>
                <Box display={{ xs: 'none', sm: 'block' }} width="300px" height="81.3px">
                  <img src={EcoLogo} width="300px" height="81.3px" alt="eco-pymes-logo" />
                </Box>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              paddingY={6}
              paddingLeft={{ xs: 0, sm: 7 }}
            >
              <Grid
                container
                xs={12}
                sm={7}
                direction="column"
                justifyContent="center"
                textAlign="start"
                order={{ xs: 2, sm: 1 }}
                paddingTop={{ xs: 4, sm: 0 }}
              >
                <Grid>
                  <Typography fontSize={28} fontWeight="bold" color="#272727" lineHeight={1}>DESCONTÁ ECHEQS Y<br /> CHEQUES DE<br /> TERCEROS</Typography>
                </Grid>
                <Grid>
                  <List>
                    <ListItem disableGutters>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={"Las tasas más bajas"} />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={"Liquidación en el día"} />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={"Beneficios impositivos"} />
                    </ListItem>
                  </List>
                </Grid>
                <Grid>
                  <Typography fontSize={15} color="#10a8a1" fontWeight={300} >
                    Valores librados por Empresas de <br /> Primera y Segunda Línea
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                xs={12}
                sm={5}
                order={{ xs: 1, sm: 2 }}

              >
                <Grid xs={12} sm={11}
                  justifyContent={{ xs: "center", sm: "flex-end" }}
                  display="flex"
                >
                  <Box height="18.5rem" width="100%" sx={{
                    background: "radial-gradient(rgba(107,62,255,0.25) 0%, rgba(255,255,255,0) 70%);"
                  }}
                  >
                    <Suspense fallback={<Spinner />}>
                      <RateChart />
                    </Suspense>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
            >
              <Grid
                container
                xs={12}
                sm={9}
                borderRadius={3}
                backgroundColor="#FFFFFF"
                justifyContent="center"
              >
                <Grid
                  container
                  xs={11}
                  textAlign="start"
                  paddingY={3}
                >
                  <Grid xs={1} display="flex" alignItems="center">
                    <GppGoodIcon color="secondary" />
                  </Grid>
                  <Grid xs={11}>
                    <Typography variant="h3" fontWeight="500" color="#272727">
                      Beneficios impositivos, seguridad y transparencia
                    </Typography>

                  </Grid>
                  <Grid xs={11} xsOffset={1}>
                    <Typography variant="h4" fontSize={18}>
                      en EcoPyMES los valores se descuentan bajo el régimen de oferta pública en el Mercado Argentino de Valores S.A.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} sm={5}
            justifyContent={{ xs: "center", sm: "flex-end" }}
            order={{ xs: 1, sm: 2 }}
          >

            <Grid container
            >
              <Grid paddingY={{ xs: 3, sm: 0 }}>
                <Box display={{ xs: 'block', sm: 'none' }} width="300px" height="81.3px">
                  <img src={EcoLogo} width="300px" height="81.3px" alt="eco-pymes-logo" />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              paddingTop={4}
              borderRadius={8}
              xs={12}
              sm={9}
              direction="column"
              backgroundColor="#FFFFFF"
              component="form"
              onSubmit={handleLogin}
            >
              <Grid textAlign="center">
                <Typography fontSize={16} color="#272727" fontWeight="bold" >
                  COTIZÁ GRATIS Y SIN COMPROMISO
                </Typography>
                <Typography fontSize={16} color="#272727" fontWeight="bold" paddingY={1}>
                  DESCUENTO DE ECHEQS Y CHEQUES DE TERCEROS
                </Typography>
                <Typography variant="div" fontSize={16} color="primary" fontWeight="bold" backgroundColor="#effff1" borderRadius={2}>
                  SIN ABRIR CUENTA
                </Typography>
              </Grid>
              <Grid container direction="column" xs={10} sm={9} paddingTop={2} paddingBottom={1}>
                <Grid>
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus={true}
                    placeholder="Email"
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid xs={3}>
                    <TextField
                      margin="dense"
                      id="phone-area-code"
                      name="phone-area-code"
                      placeholder="11"
                      type="tel"
                      variant="outlined"
                      size="small"
                      fullWidth
                      helperText="Cód. área"
                      inputProps={{ maxLength: 4 }}
                      FormHelperTextProps={{
                        sx: { marginLeft: 0.2 },
                      }}
                    />
                  </Grid>
                  <Grid xs={9}>
                    <TextField
                      margin="dense"
                      id="phone-number"
                      name="phone-number"
                      placeholder="3456-7890"
                      type="tel"
                      variant="outlined"
                      size="small"
                      fullWidth
                      helperText="Número celular (SIN EL 15)"
                      inputProps={{ maxLength: 8 }}
                      FormHelperTextProps={{
                        sx: { marginLeft: 0.2 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Typography fontStyle="italic" fontSize={13} color="#778192">
                  (No compartiremos tus datos)
                </Typography>
              </Grid>

              <Grid
                container
                justifyContent="center"
                paddingY={2}
              >
                <Grid >
                  <FormControl>
                    <FormLabel id="rg-mipyme-cert" disabled={true}>
                      Tengo certificado MiPyME vigente
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="rg-mipyme-cert"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Sí"
                      />
                      <FormControlLabel
                        value="no1"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="no2"
                        control={<Radio />}
                        label="No sé"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid>
                <Typography variant="div" fontSize={16} color="secondary" fontWeight="bold" backgroundColor="#fbefff" borderRadius={2}>
                  NO DESCONTAMOS CHEQUES PROPIOS
                </Typography>
              </Grid>
              <Grid paddingTop={2}>
                <Box width="304px" height="78px">
                  <Suspense fallback={<Spinner />}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    // onChange={(response) => setCaptchaResponse(response)}
                    />
                  </Suspense>
                </Box>
                <Box height="1rem" paddingTop={1}>
                  {loading ? (
                    <Spinner />
                  ) : null}
                </Box>
              </Grid>
              <Grid xs={8} paddingTop={3} paddingBottom={8}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                >
                  Cotizar
                </Button>

              </Grid>
            </Grid>
            <Grid container
            >
              <Grid>
                <Box display={{ xs: 'block', sm: 'none' }} paddingTop={1} textAlign="center">
                  <Typography fontSize={17} fontWeight={500} color="primary">¿Necesitás asistencia?</Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    startIcon={<WhatsAppIcon />}
                    sx={{
                      fontSize: 18,
                      fontWeight: 700,
                      borderRadius: 3
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/5491125017985?text=hola"
                  >
                    Eco Chat
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-evenly"
          xs={10}
          sm={9}
          paddingBottom={1}
        >
          <Grid>
            <CheckTextbox text={"Sin avales"} />
          </Grid>
          <Grid>
            <CheckTextbox text={"Sin scoring"} />
          </Grid>
          <Grid>
            <CheckTextbox text={"Sin SGR"} />
          </Grid>
          <Grid>
            <CheckTextbox text={"Sin consumir tu línea de crédito bancario"} />
          </Grid>
          <Grid>
            <img src={smartphoneillustration} alt="smartphone" />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        paddingY={6}
        backgroundColor="#FFFFFF"
        // alignItems="center"
        textAlign="center"
      >
        <Grid>
          <Typography variant="h2" fontWeight="bold" color="primary" display='inline' >ECOPYMES </Typography>
          <Typography variant="h2" fontWeight="400" display='inline'>
            ES UNA INICIATIVA DE
          </Typography>
        </Grid>
        <Grid >
          <Link target="_blank" rel="noreferrer" href="https://www.ecobolsar.com.ar/">
            <img src={EcoLogo2} alt="Logo" />
          </Link>
        </Grid>
        <Grid>
          <Typography variant="h2" fontWeight="400" display='inline'>
            CONECTANDO A LAS
          </Typography>
          <Typography variant="h2" fontWeight="bold" color="primary" display='inline' > PYMES </Typography>
          <Typography variant="h2" fontWeight="400" display='inline'>
            CON EL MERCADO DE CAPITALES DESDE
          </Typography>
          <Typography variant="h2" fontWeight="bold" color="primary" display='inline' > 2005</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        backgroundColor="#3e505b"
      >
        <Grid
          container
          direction="column"
          xs={12}
          sm={10}
          alignItems="start"
          paddingY={3}
          paddingX={2}
          textAlign="start"
          color="#FFFFFF"
          fontSize={14}
        >
          <Grid>EcoPyMEs 2023</Grid>
          <Grid textAlign="justify">
            ECO VALORES S.A. es un Agente de Liquidación y Compensación Propio
            registrado bajo la matrícula n° 109 y Agente de Colocación y
            Distribución Integral de FCI registrado bajo la matrícula n° 45, en
            todos los casos de la CNV. Además, Eco es miembro de Matba Rofex
            S.A, Mercado Argentino de Valores S.A. (MAV) y Bolsas y Mercados
            Argentinos S.A. (ByMA).
          </Grid>
          <Grid>
            Defensa de consumidores para reclamos &nbsp;
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario"
            >
              ingrese aquí
            </Link>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid>
            <Box
              display={{ xs: "none", sm: "block" }}
              position="fixed"
              bottom={window.innerWidth <= 1280 ? "0px" : "40px"}
              right="50px"
              textAlign="center"
            >
              <Typography fontSize={17} fontWeight={500} color="primary">
                ¿Necesitás asistencia?
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<WhatsAppIcon />}
                sx={{
                  fontSize: 18,
                  fontWeight: 700,
                  borderRadius: 3
                }}
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/5491125017985?text=hola"
              >
                Eco Chat
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
};

export default Login;
