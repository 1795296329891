import styled from "@emotion/styled";
import { device } from "./device-size";
import { Flex } from "../common/flexbox/flexbox";

export const ChildrenDiv = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  background: #f6f6f6;
  min-height: 100vh;
  @media ${device.tablet} {
    padding-bottom: 40px;
    min-height: calc(100vh - 60px);
  }
`;

export const Wrapper = styled(Flex)`
  @media ${device.tablet} {
    flex-direction: column;
  }
`;
