import React, { Suspense, lazy } from "react";
import "./App.css";
import axios from "axios";
import Cookies from "js-cookie";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { StateProvider } from "./context/global-context-provider";
import { FormDataProvider } from "./context/cheque-upload-context";
import { requestHandler } from "./utils/request-handler";
import Login from "./pages/login/login";
import ProtectedRoute from "./components/protected-route/protected-route";
import Spinner from "./components/common/spinner/spinner";
import Layout from "./components/layouts/layout.js";
const Welcome = lazy(() => import("./pages/welcome/welcome"));
const IssueSelector = lazy(() =>
  import("./pages/issue-selector/issue-selector")
);
const ChequeUplaod = lazy(() => import("./pages/cheque-upload/cheque-upload"));
const ChequePreview = lazy(() =>
  import("./pages/cheque-preview/cheque-preview")
);
const ChequeOfferData = lazy(() => import("./pages/offer-data/offer-data"));
const ChequeOfferTable = lazy(() => import("./pages/offer-table/offer-table"));
const Instructions = lazy(() => import("./pages/instructions/instructions"));
const UserConfirmation = lazy(() =>
  import("./components/errors/user-confimation.js")
);

function useGoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    if (window.location.hostname !== "localhost") {
      window.gtag("set", "page_path", currentPath);
      window.gtag("event", "page_view");
    } else {
      console.debug("Sending gtag page view", currentPath);
    }
  }, [location]);
}
const AppRoutes = () => {
  useGoogleAnalytics();
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/:producerReferral" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route path="welcome" element={<Welcome />} />
          <Route
            path="issue-selector/:paymentInstrumentType"
            element={<IssueSelector />}
          />
          <Route
            path="cheque-upload/:paymentInstrumentType"
            element={<ChequeUplaod />}
          />
          <Route
            path="cheque-preview/:paymentInstrumentType"
            element={<ChequePreview />}
          />
          <Route path="offer-data/:id" element={<ChequeOfferData />} />
          <Route path="instructions" element={<Instructions />} />
          <Route path="offer-table" element={<ChequeOfferTable />} />
          <Route path="user-confirmation" element={<UserConfirmation />} />
        </Route>
      </Route>
    </Routes>
  );
};
const App = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      let refreshToken = Cookies.get("refreshToken");
      if (
        refreshToken &&
        (error.response.status === 401 || error.response.status === 503) &&
        !originalRequest._isRetryRequest
      ) {
        originalRequest._isRetryRequest = true;
        return requestHandler(
          "/auth/token/refresh/",
          JSON.stringify({ refresh: refreshToken }),
          "post"
        ).then((res) => {
          if (res.status === 200) {
            Cookies.set("accessToken", res.data.access);
            originalRequest.headers.Authorization = `Bearer ${Cookies.get(
              "accessToken"
            )}`;
            return axios(originalRequest);
          }
        });
      }
      return Promise.reject(error);
    }
  );
  return (
    <div className="App">
      <StateProvider>
        <FormDataProvider>
          <BrowserRouter>
            <Suspense fallback={<Spinner size={50} />}>
              <AppRoutes />
            </Suspense>
          </BrowserRouter>
        </FormDataProvider>
      </StateProvider>
    </div>
  );
};

export default App;
